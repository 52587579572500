import request from '@/utils/request'

//一口价
const editProduct = (data)=> request('/pc/activity-bale/edit-product',data);
//限时折扣
const discountchoseproductlist = (data) => request('/pc/timelimitdiscount/discountchoseproductlist', data);
// 获取优惠券的弹窗产品列表
const activitycouponChoseproductlist = (data) => request('/admin/goods/getPagedListForAct', data);
// 获取满减送弹窗产品列表
const activityMarkdownGiftChoseProduct = (data) => request('/pc/ActivityMarkdownGift/ChoseProduct', data);
//获取积分管理弹窗产品列表
const pointconfigjoinproductlist = (data) => request('/pc/pointgiving/pointgivingconfigjoinproduct', data);

// 会员等级商品列表
export const memberVipRoleProduct= (data) => request('/pc/memberVipRole/productList',data);

// 支付有礼-商品列表
export const activityPayGiftProductList= (data) => request('/pc/activityPayGift/productList',data);

// 添加复团商品分页
export const afterGroupProductList= (data) => request('/admin/afterGroup/findPageGoods',data);

//优惠券列表（批量发优惠券适用）
export const vipBatchCouponList= (data) => request('/admin/card/list',data);
// 获取弹窗产品列表 微页面使用商品列表
const activityproductmicrolist = (data) => request('/admin/goods/goodsList/micro', data);
export default {
	editProduct,
	discountchoseproductlist,
	activitycouponChoseproductlist,
	activityMarkdownGiftChoseProduct,
	pointconfigjoinproductlist,
	memberVipRoleProduct,
	activityPayGiftProductList,
	afterGroupProductList,
	vipBatchCouponList,
	activityproductmicrolist,
}



















































































