<template>
	<div class="suppierDetail">
		<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" v-loading='loading'>
			<el-card class="box-card">
				<div slot="header" class="clearfix">
					<span>基本信息</span>
				</div>
				<div class="content" style="padding-bottom: 100px;">

					<el-form-item label="活动名称：" prop="Name">
						<el-input v-model="ruleForm.Name" :disabled="currentEditStatus==1" style="width:400px;" clearable placeholder="最多输入20个字"
						maxlength="20"></el-input>
					</el-form-item>

					<el-form-item label="活动时间：" prop="activityTime">
						<el-date-picker v-model="ruleForm.activityTime" type="datetimerange" :picker-options="pickerOptions"
						range-separator="～" start-placeholder="开始日期" end-placeholder="结束日期" align="right" :disabled="currentEditStatus==1 || currentEditStatus==2"
						value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
					</el-form-item>
					<el-form-item label="参与会员类型：" prop="checkList">
						<el-checkbox-group :disabled="currentEditStatus==1" v-model="ruleForm.checkList">
							<el-checkbox label="0">注册用户</el-checkbox>
							<el-checkbox :label="level.pusherLevel" v-for="(level,index) in LEVELLIST" :key='index'>{{level.pusherLabel}}</el-checkbox>
							
						</el-checkbox-group>
					</el-form-item>
					<el-form-item label="消费方式：" prop="moneyRadio" required>
						<el-radio-group :disabled="currentEditStatus==1" @change="moneyWayChange" v-model="ruleForm.moneyRadio">
							<el-radio label="1">购买一定金额的商品</el-radio>
							<el-radio label="2">购买某一款商品</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item v-if="ruleForm.moneyRadio==1" label="消费满：" prop="fullMoney">
						<el-input v-if="ruleForm.moneyRadio==1" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"
						v-model="ruleForm.fullMoney" :disabled="currentEditStatus==1" style="width:150px;" placeholder="填写消费金额"
						maxlength="10"></el-input><span style="margin-left: 10px;">元，参与活动</span>
					</el-form-item>
					<el-form-item v-if="ruleForm.moneyRadio==2" label="选择商品" prop="selectedList">
						<div style="align-items: center;">
							<el-button type="primary" :disabled="currentEditStatus==1" @click="choseActive">选择商品</el-button>
						</div>
					</el-form-item>
					<el-table v-if="ruleForm.moneyRadio==2" :data="ruleForm.selectedList" style="width: 100%;margin-bottom: 20px;" ref="productChose">
						<el-table-column label="商品" width="400">
							<template slot-scope="scope">
								<div class="product-info">
									<img v-if="scope.row.thumbImgUrl" :src="scope.row.thumbImgUrl" />
									<svg-icon v-else icon-class="noImgIcon" />
									<div>
										<div>
											<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.goodsName}}</pre>
										</div>
										<span v-if="scope.row.totalStock<1" style="color: #E51C23;">已售罄</span>
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column prop="originalPrice" label="划线价"></el-table-column>
						<!-- <el-table-column prop="salePrice" label="销售价"></el-table-column> -->
						<!-- <el-table-column prop="totalStock" label="库存"></el-table-column> -->
						<el-table-column label="操作" width="200" >
							<template slot-scope="scope">
								<span style="color:#C0C4CC;" v-if="currentEditStatus==1">删除</span>
								<span style="color:#F56C6C;cursor:pointer;" v-else @click="deleteChose(scope.row)">删除</span>
							</template>
						</el-table-column>
					</el-table>
					<el-form-item label="领奖节点：" prop="awardsWay" required>
						<el-select :disabled="currentEditStatus==1" v-model="ruleForm.awardsWay" placeholder="请选择领奖节点">
							<el-option v-for="item in statusOptions" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="赠送优惠券：" prop="couponValue" required>
						<el-select :disabled="currentEditStatus==1" filterable clearable v-model="ruleForm.couponValue" placeholder="请选择要赠送的优惠券">
							<el-option v-for="item in couponList" :key="item.id" :label="item.couponName" :value="item.id">
							</el-option>
						</el-select>
					</el-form-item>
				</div>
			</el-card>
		</el-form>
		<el-dialog title="选择商品" :visible.sync="addProductDialogShow" width="1400px" class="dialog">
			<select-produce api="activitycouponChoseproductlist" :params="['goodsName', 'barCode']" :selectedData="ruleForm.selectedList"
			@getSelectList="getSelectList" :visible.sync="addProductDialogShow" v-if="addProductDialogShow"></select-produce>
		</el-dialog>
		<div class="footer">
			<div v-if="currentEditStatus!=1">
				<el-button style="width:240px;" @click="suppilerCansel">取消</el-button>
				<el-button style="width:240px;" type="primary" @click="saveSupiler('ruleForm')" :loading="loading">保存</el-button>
			</div>
			<div v-else>
				<el-button style="width:240px;" @click="suppilerBack">返回</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		findByCouponName,
		addOrUpActivity,
		orderPolitefindOne,
		orderPoliteupdateAct
	} from '@/api/wyUsedInterface.js'
	import selectProduce from '../coupon/selectMultiPro.vue';
	export default {
		components: {
			selectProduce
		},
		data() {
			var checkName = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('请输入活动名称'));
				} else if (!value.trim()) {
					return callback(new Error('请输入活动名称'));
				} else {
					return callback()
				}
			};
			return {
				loading: false,
				ruleForm: {
					Name: '',
					activityTime: '',
					moneyRadio: '1',
					fullMoney: '',
					checkList: ['0', '3','4', '5', '6'],//['0', '1', '2', '3', '4', '5', '6']
					awardsWay: '',
					couponValue: '',
					selectedList:[],
				},
				couponList:[],
				currentCount: '',
				rules: {
					Name: [{
						required: true,
						trigger: 'blur',
						validator: checkName
					}],
					activityTime: [{
						required: true,
						message: '请选择活动时间',
						trigger: 'change'
					}],
					couponValue: [{
						required: true,
						message: '请选择要赠送的优惠券',
						trigger: 'change'
					}],
					fullMoney:[{
						required: true,
						trigger: 'blur',
						message: '请填写消费金额',
					}],
					awardsWay: [{
						required: true,
						message: '请选择领奖节点',
						trigger: 'change'
					}],
					checkList: [{
						type: 'array',
						required: true,
						message: '请选择参与会员类型，至少选一个',
						trigger: 'change',
					}],
					selectedList:[{
						type: 'array',
						required: true,
						message: '请选择商品',
						trigger: 'change',
					}],
				},
				statusOptions: [{
						label: '支付成功',
						value: 1
					},
					{
						label: '订单完成',
						value: 2,
					},
				],
				currentEditId: '',
				currentEditCopyId: '',
				currentEditStatus: null,
				pickerOptions: { //时间区间提示
					shortcuts: [{
						text: '最近一周',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
							picker.$emit('pick', [start, end]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const end = new Date();
							const start = new Date();
							start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
							picker.$emit('pick', [start, end]);
						}
					}],
				},
				addProductDialogShow:false,
			}
		},
		created() {
			this.currentEditId = this.$route.query.id||0;
			// currentEditStatus:1=查看，不可编辑所有
			this.currentEditStatus = this.$route.query.type;
			this.findByCouponName();
			let _this = this;
			if (this.currentEditId>0) {
				setTimeout(function(){
					_this.getData();
				},200)
			}
		},
		methods: {
			async getData() {
				this.loading = true;
				try {
					let data = {
						id: this.currentEditId
					}
					let result = await orderPolitefindOne(data);
					var list = [];
					var typeLit =JSON.parse(result.data.userType);
					typeLit.map(item=>{
						list.push(item+'');
						return item;
					})
					this.editInfo = result.data;
					this.ruleForm = {
						Name: result.data.actName,
						activityTime: [result.data.begTime,result.data.endTime],
						moneyRadio:result.data.expenseType+'',
						fullMoney:result.data.expenseType==1?result.data.expMoney:'',
						checkList:list,
						awardsWay:result.data.getType,
						couponValue:result.data.couponId,
						selectedList:result.data.expenseType==1?[]:result.data.list
					}
					this.$nextTick(() => {
						this.$refs['ruleForm'].clearValidate()
					})
					// this.initForm()
				} catch (error) {
					this.loading = false
				} finally {
					this.loading = false;
				}
			},
			// 取消
			suppilerCansel() {
				this.$confirm('您还未保存编辑的数据，离开页面后，系统将不会为您保存数据，是否确认继续离开？', '提示', {
					confirmButtonText: '确认离开',
					cancelButtonText: '关闭',
					type: 'warning'
				}).then(() => {
					this.$router.push({
						path: '/market/orderCouponBack/index'
					});
				})
			},
			suppilerBack() {
				this.$router.push({
					path: '/market/orderCouponBack/index'
				});
			},
			// 保存，处理数据
			async saveSupiler(ruleForm) {
				var list = [];
				this.ruleForm.checkList.map(item=>{
					list.push(parseInt(item));
					return item;
				})
				this.$refs[ruleForm].validate(async (valid) => {
					if (valid) {
						if(this.ruleForm.moneyRadio=='1'){
							if(this.ruleForm.fullMoney.length==0){
								this.$message({
									showClose: true,
									type: 'error',
									message:'请填写消费金额'
								});
								return;
							}else if(this.ruleForm.fullMoney=='0'){
								this.$message({
									showClose: true,
									type: 'error',
									message:'消费金额需大于0'
								});
								return;
							}
						}else if(this.ruleForm.moneyRadio=='2'){
							console.log("--111------",this.ruleForm.selectedList)
							if(this.ruleForm.selectedList.length==0){
								this.$message({
									showClose: true,
									type: 'error',
									message:'请选择活动商品'
								});
								return;
							}
						}
						this.loading = true
						var ids = [];
						if(this.ruleForm.moneyRadio=='2'){
							this.ruleForm.selectedList.map(item=>{
								var obj = {
									goodsId:item.id
								};
								ids.push(obj);
								return item;
							})
						}
						try {
							let data = {
								actName: this.ruleForm.Name,
								begTime: this.ruleForm.activityTime[0],
								endTime: this.ruleForm.activityTime[1],
								getType: this.ruleForm.awardsWay,//领取类型：1支付完成，2订单完成
								expenseType: parseInt(this.ruleForm.moneyRadio),//消费类型：1，购买一定金额的商品，2购买某已款商品
								expMoney:this.ruleForm.moneyRadio==1 ? parseFloat(this.ruleForm.fullMoney) : '',//消费满XXX金额
								couponId: this.ruleForm.couponValue,//优惠券id
								goodsRequests:ids,//商品id
								userType: JSON.stringify(list),//会员类型数组[0,1,2,3,4,5,6]
							}
							let res = null;
							if(this.currentEditId>0){
								data.id = this.currentEditId;
								res = await orderPoliteupdateAct(data);
							}else{
								res = await addOrUpActivity(data);
							}
							if(res.success){
								this.$router.push({
									path: '/market/orderCouponBack/index'
								});
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功'
								});
							}else{
								this.$message({
									showClose: true,
									type: 'error',
									message:res.alertMsg?res.alertMsg: '操作失败'
								});
							}
							
							this.loading = false
						} catch (error) {
							console.log(error);
							this.$message({
								showClose: true,
								type: 'error',
								message: '操作失败'
							});
							this.loading = false
						} finally {
							this.loading = false
						}
					}
				})
			},
			moneyWayChange(val) {
				this.$nextTick(() => {
					this.$refs['ruleForm'].clearValidate()
				})
				console.log(val);
			},
			async findByCouponName(){
				try{
					let res = await findByCouponName({});
					if(res.success){
						this.couponList = res.data;
					}
				}catch(e){
					//TODO handle the exception
				}
			},
			choseActive() {
				// if (this.onlyLook) {
				// 	return;
				// }
				this.addProductDialogShow = true;
			},
			getSelectList(data) {
				// if (data.length > 1) {
				// 	this.$message({
				// 		showClose: true,
				// 		type: 'error',
				// 		message: '最多选择一个商品！'
				// 	})
				// 	return;
				// }
			
				this.addProductDialogShow = false
				this.ruleForm.selectedList = data;
				// console.log("---selectedList----", this.ruleForm.selectedList)
			},
			deleteChose(val) {
				this.ruleForm.selectedList = this.ruleForm.selectedList.filter(t => {
					if (t.id != val.id) {
						return t
					}
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	.suppierDetail {
		background-color: #fff;

		// margin: 30px;
		.remarks {
			color: #bbb;
			font-size: 14px;
		}

		.parent-box {
			position: relative;

			.close-bosx {
				position: absolute;
				right: -5px;
				top: -13px;
				font-size: 18px;
			}
		}

		.markRed {
			position: absolute;
			left: -94px;
			top: 5px;
			color: red;
			font-size: 18px;
		}
	}

	.dialog {
		width: 100%;

		/deep/.el-dialog__header {
			border-bottom: 1px solid #ddd;
		}
	}

	.table-container {
		position: relative;
		overflow: hidden;
	}

	.dialog-name-content {
		display: flex;
		flex-direction: row;

		img {
			width: 60px;
			height: 60px;
			object-fit: cover;
			margin-right: 10px;
		}

		.name {
			width: 200px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			-webkit-box-orient: vertical;
		}

		span {
			color: #E51C23;
			font-size: 12px;
		}
	}

	.el-form-date-pick {
		display: inline-block;
	}

	.radio-group {
		display: block;
	}

	.footer {
		text-align: center;
		line-height: 80px;
		background-color: #fff;
		position: fixed;
		bottom: 0px;
		width: 100%;
		box-shadow: 13px 1px 6px #999;
	}

	input[type=number]::-webkit-inner-spin-button {
		-webkit-appearance: none;
	}

	.number__input {
		border: 1px solid #ccc;
	}
</style>
